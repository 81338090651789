import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import { useEffect, useState } from 'react'
import './App.css';
import { loadPlaces } from "./places.js"

// For external links (new window and no referrer)
function E({href, children}) {
  return (<a href={href} target="_blank" rel="noreferrer">{children}</a>)
}

function PlacePopup({ place }) {
  return (<div>
    <div>{place.name}</div>
    {place.company && <div>Company: {place.company}</div>}
    {place.role && <div>Role: {place.role}</div>}
    <div>Address: {place.streetAddress}</div>
    {place.city && <div>City: {place.city}</div>}
    {place.postcode && <div>Post code: {place.postcode}</div>}
    <div><a href={`https://duckduckgo.com/?q=${place.lat}, ${place.lng}&iaxm=maps`} target="_blank" rel="noreferrer">Directions</a></div>
    {place.phone && <div>Phone: <a href={`tel:${place.phone}`}>{place.phone}</a></div>}
    {place.email && <div>Email: <a href={`mailto:${place.email}`}>{place.email}</a></div>}
    {place.website && <div>Website: <E href={place.website}>{place.website}</E></div>}
    {[{
      name: 'birthdate',
      label: 'Birthdate',
    }, {
      name: 'allabolag',
      label: 'Allabolag.se',
      type: 'external',
    }, {
      name: 'upplysning',
      label: 'Upplysning.se',
      type: 'external',
    }, {
      name: 'licenseplate',
      label: 'License plate',
    }, {
      name: 'linkedin',
      label: 'LinkedIn',
      type: 'external',
    }, {
      name: 'socialmedia',
      label: 'Social media',
      type: 'external',
    }, {
      name: 'kids',
      label: 'Kids',
    }, {
      name: 'spouse',
      label: 'Spouse',
    }].map(field => {
      if (!place[field.name]) return null

      if(field.type === 'external') {
        return <div><E href={place[field.name]} key={field.name}>{field.label}</E></div>
      } else {
        return <div key={field.name}>{field.label}: {place[field.name]}</div>
      }
    })}
  </div>)
}

function App() {
  const [places, setPlaces] = useState([])

  useEffect(() => {
    if (places.length === 0) {
      loadPlaces().then(places => {
        setPlaces(places)
      })
    }
  }, [places, setPlaces])

  return (
    <div className="App">
      Aimpoint is a company that produces weapon sights that are sold to the <E href="https://www.etc.se/inrikes/sikten-fraan-svenska-aimpoint-anvaends-av-idf">Israeli military</E>. This map shows people connected to Aimpoint and also stores that sell Aimpoint products.
      { places.length === 0 && <div>Loading...</div> }
      <MapContainer style={{ height: '90vh' }} center={[55.6088, 12.9997]} zoom={11} maxZoom={19} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {places.map(place => (
          <Marker position={[place.lat, place.lng]} key={`${place.name}${place.lat}${place.lng}`}>
            <Popup>
              <PlacePopup place={place} />
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <a href="mailto:blodspengar@riseup.net">Contact us</a>. <a href="blodspengar.asc">GPG key</a>.
    </div>
  );
}

export default App;
